import axios from 'axios'

// create an axios instance
const service = axios.create({
	baseURL: '',
	timeout: 50000, // request timeout
	withCredentials: false
})
// request interceptor
service.interceptors.request.use(
	config => {
		config.headers["Content-Type"] = "application/json";
		config.headers["app_version"] = "1.8";
		config.headers["device"] = "pc";
		return config
	},
	error => {
		Promise.reject(error)
	}
)

// respone interceptor
service.interceptors.response.use(
	response => {
		if (response.status === 200 || response.status === 201 || response.status === 204) {
			const res = response.data
			return res
		}
	},
	error => {
		return Promise.reject(error)
	}
)

export default service

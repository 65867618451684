import Vue from 'vue'
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes =  [
		{
			path:'/',
			name:'index',
			component: ()=>import('@/views/index'),
		},{
			path:'/live',
			name:'live',
			component: ()=>import('@/views/live'),
		}
	]
// 将路径注入到VueRouter中
const router = new VueRouter({
	'mode': 'history',
	routes
})
// 导出路由
export default router;

import request from './request'

export function getApiByGet (params) {
	return request({
		url: params.url,
		method: 'get',
		params: params.params,
		headers:  params.headers,
		dataType: 'json'
	})
}

export function getApiByPost (params) {
	return request({
		url: '/' + params.url,
		method: 'post',
		data: params.data,
		headers: params.headers,
	})
}
export function getApiByDel (params) {
	return request({
		url: '/' + params.url,
		method: 'delete',
		data: params.data,
		headers:params.headers,
	})
}
export function getApiByPut (params) {
	return request({
		url: '/' + params.url,
		method: 'put',
		data: params.data,
		headers:params.headers,
	})
}

export function param (data) {
	let url = ''
	for (var k in data) {
		let value = data[k] !== undefined ? data[k] : ''
		url += '&' + k + '=' + encodeURIComponent(value)
	}
	return url ? url.substring(1) : ''
}

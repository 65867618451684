import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		livekey: null,
		logoArr:[],
	},
	mutations: {
		setLogoArr(state,msg){
			state.logoArr = msg
		}
	},
	actions: {},
	modules: {}
});

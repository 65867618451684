import {getApiByGet,getApiByPost,getApiByDel} from './axios'

// 用户信息接口（示例）
export const api = {
	wxShare:params => getApiByGet({
		url:'wx/v1/weixin/ticket'+params.url,
		params:params.data,
		headers: JSON.parse(JSON.stringify(params.headers))
	}).then(ok=>{
		if (ok.errCode){
			console.log('出错了')
		}else{
			return ok
		}
	}),
	liveStreamList:params => getApiByGet({
		url:'api/v1/watch/list'+params.url,
		params:params.data,
		headers: params.headers || {}
	}).then(ok=>{
		if (ok.result == 'ok'){
			return ok
		}else{
			console.log('出错了')
		}
	}),
	liveStream:params => getApiByGet({
		url:'api/v1/watch/'+params.url,
		params:params.data,
		headers: params.headers || {}
	}).then(ok=>{
		if (ok.result == 'ok'){
			return ok
		}else{
			console.log('出错了')
		}
	}),
	liveUrl:params => getApiByGet({
		url:'api/v1/watch/'+params.url+'/info?fields=tags;stream_source;play_url;video_url;share_qrcode;status;stream_source;page_back_image;video_url;video_replay_url;is_replay;is_talk;replay_image;number_of_reads;if_current_user_voted;share_title;share_image;share_description;title;description;play_url;number_of_upvotes;web_title;main_logo;vice_logo;is_show_statement;live_status',
		params:params.data,
		headers: params.headers || {}
	}).then(ok=>{
		if (ok.result == 'ok'){
			return ok
		}else{
			console.log('出错了')
		}
	}),
	liveTagsUrl:params => getApiByGet({
		url:'api/v1/posts/list/tag/'+params.guid+'?fields=thumb_image&is_tag_guid=true',
		params:params.data,
		headers: params.headers || {}
	}).then(ok=>{
		if (ok.result == 'ok'){
			return ok
		}else{
			console.log('出错了')
		}
	}),
	addUpvotes:params => getApiByPost({
		url:'api/v1/entities/'+params.guid+'/upvotes',
		params:params.data,
		headers: params.headers || {}
	}).then(ok=>{
		if (ok.result == 'ok'){
			return ok
		}else{
			console.log('出错了')
		}
	}),
	delUpvotes:params => getApiByDel({
		url:'api/v1/entities/'+params.guid+'/upvotes',
		params:params.data,
		headers: params.headers || {}
	}).then(ok=>{
		if (ok.result == 'ok'){
			return ok
		}else{
			console.log('出错了')
		}
	}),
	addPolyxImg:params => getApiByPost({
		url: params.url,
		params:params.data,
		headers: {

		}
	}).then(ok=>{
		if (ok.result == 'ok'){
			return ok
		}else{
			console.log('出错了')
		}
	}),
}

<template>
  <div id="app" >
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',

  computed:{
    pageStyle(){
      if (this.$route.name == 'live'){
        return "height:100%;overflow-y:auto;"
      }else{
        return "min-height:100%"
      }
    }
  },
  data(){
    return{
      myWidth: '',
    }
  },
  created() {
    if (localStorage.getItem("store") ) {
      this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(localStorage.getItem("store"))))
    }

    window.addEventListener('beforeunload',()=>{
      localStorage.setItem("store",JSON.stringify(this.$store.state))
      return true
    });
    window.onload = function() {
      document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function(event) {
        event.preventDefault()
      })
    }
  },
  mounted() {
    let that = this;
    that.wResize();
    this.myWidth = window.innerWidth
    window.onresize = function windowResize() {
      that.myWidth = window.innerWidth;
      that.wResize();
    }

  },
  methods:{
    wResize(){
      let _this = this;
      var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
      if ( deviceWidth < 1140) {
        document.documentElement.style.fontSize = (deviceWidth / 37.5) + 'px';
        _this.$root.$device = 'h5';
      }else{
        _this.$root.$device = 'pc';
      }
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/css/base.sass";
*{
  box-sizing: border-box;
}
body{
  width: 100%;
  height: 100%;
}
#app{
  width: 100%;
  min-height:100%;
  background-size: 100% auto;
  background-repeat: repeat-y;
  box-sizing: border-box;
  @media screen and(max-width: 1139px){
    background-size: 100% auto;
    background-repeat: repeat-y;
  }

  .container{
    width: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    @media screen and(max-width: 1139px){
      width: 100vw;
      min-height: calc(100vh - 4.8rem);
    }
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 配置axios start
import axios from 'axios'
Vue.prototype.$http = axios;
import {api} from './api/apis'
Vue.prototype.$api = api;

// 声明md5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
// 配置video-player插件 start
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import 'videojs-flash'
import 'videojs-contrib-hls'  //.m3u8格式引入，可以直接引入，必须放到videojs-flash之后两个才都可以用，否则，flash报错
Vue.use(VueVideoPlayer)
// 配置video-player插件 end

// 构建vue
new Vue({
  data(){
    return{
      $device: 'pc'
    }
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
